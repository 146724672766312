<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			readOnly
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:parentModel="parentModel"
			:masterDetailColumnVisibles="masterDetailColumnVisibles"
			:readOnly="readOnly"
		></pui-datatable>
		<partida-modals :modelName="modelName"></partida-modals>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import partidaActions from './PartidaActions';
import partidaModals from './PartidaModals.vue';

export default {
	name: 'partida-grid',
	components: {
		'partida-modals': partidaModals
	},
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'partida',
			actions: partidaActions.gridactions,
			masterDetailColumnVisibles: {
				idnotif: false,
				numdoc: false,
				numpartida: true,
				onu: true,
				clase: true,
				categoriaestiba: true,
				art15: true,
				envcumpleimdg: true,
				nomtecnico: true,
				numbultos: true,
				pesoneto: true,
				pesobruto: true,
				grupoenvasado: true
			},
			modelColumnDefs: {
				envcumpleimdg: {
					render: (data) => {
						if (data === 'S') {
							return "<i aria-hidden='true' class='v-icon fa fa fa fa-check-circle theme--light green--text' style='font-size: 16px;'></i>";
						} else {
							return "<i aria-hidden='true' class='v-icon fa fa fa fa-ban theme--light red--text' style='font-size: 16px;'></i></label>";
						}
					}
				},
				art15: {
					render: (data) => {
						if (data === 'S') {
							return "<i aria-hidden='true' class='v-icon fa fa fa fa-check-circle theme--light green--text' style='font-size: 16px;'></i>";
						} else {
							return "<i aria-hidden='true' class='v-icon fa fa fa fa-ban theme--light red--text' style='font-size: 16px;'></i></label>";
						}
					}
				},
				art15maestro: {
					render: (data) => {
						if (data === '1') {
							return "<i aria-hidden='true' class='v-icon fa fa fa fa-check-circle theme--light green--text' style='font-size: 16px;'></i>";
						} else {
							return "<i aria-hidden='true' class='v-icon fa fa fa fa-ban theme--light red--text' style='font-size: 16px;'></i></label>";
						}
					}
				}
			}
		};
	},
	created() {
		if (this.masterDetail) {
			this.$puiEvents.$on(`onPuiGotoTableDetail-${this.parentModelName}`, () => {
				var thisModel = this.$store.getters.getModelByName(this.modelName);
				if (thisModel && this.parentModel) {
					thisModel.storedData = {};
					thisModel.storedData.parentModelName = this.parentModelName;
					thisModel.storedData.numdoc = this.parentModel.numdoc;
					thisModel.storedData.numescent = this.parentModel.numescent;
				}
			});

			this.$store.dispatch('loadDefaultGridConfiguration', this.modelName);
		}
	}
};
</script>
